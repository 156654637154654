import { VALID_LANGUAGES } from "@js/i18n/use-i18n";

export const objectToArray = (object) => {
  return Object.keys(object).map((key) => object[key]);
};

/**
 * Resolve current language from www.drupal.org/project/decoupled_router.
 *
 * @see src/js/client.jsx
 * @ses src/js/admin-app.jsx
 * @see src/js/server.js
 *
 * @param pathName string
 * @returns {string}
 */
export const getCurrentLanguage = (pathName) => {
  let currentLanguage = "und";
  // @see https://stackoverflow.com/a/47353851
  const languageRegex = new RegExp(
    `/((${VALID_LANGUAGES.join("|")})/?$|(${VALID_LANGUAGES.join("|")})/)`
  );

  if (languageRegex.test(pathName)) {
    currentLanguage = pathName.substring(1, 3);
  }

  return currentLanguage;
};
