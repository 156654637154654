import React from "react";
import { SocialMedia } from "@js/footer/components/social-media";
import { Menu } from "@general-components/navigation/menu";
import PropTypes from "prop-types";
import RBXLogo from "@/assets/rbx-company/RBX-Logo-white.svg";
import { Link } from "react-router-dom";

const RBXFooter = ({ footerConfig }) => {
  return (
    <footer id="page-footer" className="rbx">
      <div className="container-fluid">
        {footerConfig && (
          <div className="row">
            <div className="col-16 col-lg-8 footer main">
              <div className="footer-menu row">
                <div className="col-16 col-lg-8">
                  <img src={RBXLogo} className="rbx-logo" alt={"Logo RBX"} />
                  {!!footerConfig.fieldRbxFooterText && (
                    <div
                      className="text"
                      dangerouslySetInnerHTML={{
                        __html: footerConfig.fieldRbxFooterText,
                      }}
                    />
                  )}
                </div>
                <div className="col-16 col-lg-8">
                  {!!footerConfig.fieldSitemap?.links.length && (
                    <Menu
                      menuTree={footerConfig.fieldSitemap}
                      ariaLabel={"footer menu"}
                    />
                  )}
                </div>
                {!!footerConfig.fieldLegalMenu?.links.length && (
                  <div className="col-16 col-lg-8 offset-lg-8 legal-menu-wrapper">
                    <Menu
                      menuTree={footerConfig.fieldLegalMenu}
                      ariaLabel={"legal menu"}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className={"col-16 col-lg-8 footer brands"}>
              {!!footerConfig.fieldBrands?.length && (
                <div className="brands-wrapper row">
                  {!!footerConfig.fieldBrandsHeadline && (
                    <h4 className="col-16 col-lg-12 offset-lg-2 headline-s brand-headline">
                      {footerConfig.fieldBrandsHeadline}
                    </h4>
                  )}
                  <ul className="col-16 col-lg-14 offset-lg-2">
                    {footerConfig.fieldBrands.map((brand, brandIndex) => (
                      <li key={brandIndex}>
                        <Link to={brand.url.path.replace("/", "/#")}>{brand.label}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </footer>
  );
};

/*RBXFooter.propTypes = {
  footerConfig: PropTypes.func,
  socialMediaConfig: PropTypes.func
};*/

export default RBXFooter;
