import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql, withApollo } from "@apollo/client/react/hoc";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { connect } from "react-redux";
import axios from "axios";
import YAML from "yaml";
import { injectIntl } from "react-intl";

import ErrorFocus from "./elements/error-focus";
import LoadingIndicator from "../../../../general-components/loading-indicator";
import webformQuery from "./webform.graphql";
import submitMutation from "./submitMutation.graphql";
import { restHostBackend } from "../../../../config";
import WebformElements from "./webform-elements";
import { states } from "./states";
import { getItemCompositeAddressElements } from "./elements/composite-address";
import { ApolloClient, gql, useQuery } from "@apollo/client";

const WEBFORM_OPTIONS_QUERY = gql`
  query QueryWebformOptions($id: ID!) {
    entityById(entityType: WEBFORM_OPTIONS, id: $id) {
      ... on WebformOptions {
        options
      }
    }
  }
`;

const mapStateToProps = (reduxStore) => ({ reduxStore });
const webformElementsNotProcess = [
  "markup",
  "webform_actions",
  "hidden",
  "webform_markup",
];
export const webformElementsCustomExcluded = [
  "editor",
  "rating",
  "accepted",
  "denied",
  "badge",
];
// @todo Add details.
const webformElementsContainer = [
  "webform_custom_composite",
  "webform_address_composite",
  "fieldset",
  "webform_flexbox",
  "container",
];
const webFormElementsNoLevel = [
  "fieldset",
  "webform_flexbox",
  "container",
  "details",
];

const objectToArray = (object) => {
  // Respect containers.
  for (const [key, value] of Object.entries(object)) {
    if (object[key]["#type"] === "webform_custom_composite") {
      object[key]["elements"] = objectToArray(object[key]["#element"]);
      delete object[key]["#element"];

      continue;
    }
    for (const [subKey, subValue] of Object.entries(value)) {
      if (
        typeof subValue === "object" &&
        subKey !== "#options" &&
        subKey !== "#default_value"
      ) {
        const items = object[key]["elements"] ? object[key]["elements"] : [];

        object[key]["elements"] = [...items, { id: subKey, ...subValue }];
      }
    }
  }

  return Object.keys(object).map((key) => ({
    ...object[key],
    id: key,
  }));
};

export const getValidationSchema = (item, intl, values = null) => {
  let itemSchema;
  // @todo Translation!
  console.log(item);
  switch (item["#type"]) {
    case "webform_custom_composite":
      itemSchema = yup.array();
      break;

    case "textfield":
    case "textarea":
      itemSchema = yup.string();

      if (item["#maxlength"]) {
        itemSchema.max(item["#maxlength"]);
      }
      if (item["#minlength"]) {
        itemSchema.min(item["#minlength"]);
      }

      break;

    // @todo Make date more robust.
    case "date":
      itemSchema = yup.string();
      break;

    case "url":
      itemSchema = yup.string().matches(
        // @see https://stackoverflow.com/a/68002755
        /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm,
        "Bitte geben Sie eine valide URL ein!"
      );
      break;

    case "email":
      itemSchema = yup
        .string()
        .email("Bitte geben Sie eine valide E-Mail Adresse ein!");
      break;

    case "number":
      // @todo min, max, step.
      itemSchema = yup.number();
      break;

    case "managed_file":
      itemSchema = yup.string();
      break;

    case "radios":
      itemSchema = yup.string();
      break;

    case "checkbox":
      itemSchema = yup.boolean();
      break;

    case "checkboxes":
    case "select":
    case "webform_entity_select":
    case "webform_term_select":
      itemSchema = yup.array();

      if (item["#multiple"]) {
        itemSchema = itemSchema.max(
          item["#multiple"],
          item["#multiple_error"]
            ? item["#multiple_error"]
            : `Maximal ${item["#multiple"]} Werte sind zulässig!`
        );
      }

      // Select is Single by default
      if (
        !item["#multiple"] &&
        ["select", "webform_entity_select", "webform_term_select"].includes(
          item["#type"]
        )
      ) {
        itemSchema = yup.string();
      }

      break;
  }

  // Mark as not required if there are 'visible' states, in that case
  // the logic is handled via the required prop on the field...
  // ...which does not work, so the mutations returns an error which
  // is shown to the user.
  let visible = true;
  if (values) {
    visible = states(item.states, values).visible;
  }

  if (
    (item["#required"] && (item.states === null || !item.states) && !values) ||
    //(!item.states && !values) ||
    (item["#required"] && visible && values)
  ) {
    const requiredMessage = item["#required_error"]
      ? item["#required_error"]
      : `${item["#title"]} ${intl.formatMessage({
          id: "webform.required_msg",
        })}`;

    if (item["#type"] === "checkbox") {
      itemSchema = itemSchema.oneOf([true], requiredMessage);
    } else {
      itemSchema = itemSchema.required(requiredMessage);
    }
  }

  return itemSchema;
};

/**
 * Used to generate the initialValues.
 *
 * @param item
 * @returns {*[]|string|{}[]|*}
 */
export const getInitialValue = (item) => {
  let subElements =
    item["#type"] === "webform_address_composite"
      ? getItemCompositeAddressElements(item)
      : item.elements;

  if (item["#type"] === "webform_address_composite") {
    if (item["#default_value"]) {
      subElements = getItemCompositeAddressElements(item).map(
        (addressElement) => {
          if (
            Object.prototype.hasOwnProperty.call(
              item["#default_value"],
              addressElement.id
            )
          ) {
            return {
              ...addressElement,
              "#default_value": !item["#multiple"]
                ? item["#default_value"][addressElement.id]
                : [item["#default_value"][addressElement.id]],
            };
          }

          return addressElement;
        }
      );
    }
  }

  if (webformElementsContainer.includes(item["#type"])) {
    // Composite or container elements.
    let containerElements = {};

    subElements.forEach((containerItem) => {
      // Do not process these element types.
      if (
        webformElementsNotProcess.includes(containerItem["#type"]) ||
        webformElementsCustomExcluded.includes(containerItem.id)
      ) {
        return;
      }

      containerElements[containerItem.id] = getInitialValue(containerItem);
    });

    return item["#type"] === "webform_address_composite" ||
      webFormElementsNoLevel.includes(item["#type"]) ||
      (item["#type"] === "webform_custom_composite" && !item["#multiple"])
      ? containerElements
      : [containerElements];
  } else {
    if (item["#default_value"]) {
      return item["#default_value"];
    } else {
      if (item["#type"] === "checkbox") {
        return false;
      }

      if (item["#type"] === "checkboxes") {
        if (
          !item["#multiple"] ||
          (item["#multiple"] && item["#multiple"].length > 1)
        ) {
          return [];
        }
        return "";
      }

      return [
        "select",
        "webform_term_select",
        "webform_entity_select",
      ].includes(item["#type"]) && item["#multiple"]
        ? []
        : "";
    }
  }
};

class ParagraphForm extends Component {
  state = {
    submitResponse: null,
    token: "",
    defaultInitialValues: this.props.defaultInitialValues,
    elements: this.props.data?.entityById?.elements
      ? objectToArray(YAML.parse(this.props.data.entityById.elements))
      : [],
    elementsYML: this.props.data?.entityById?.elements,
  };

  formContainer = React.createRef();

  componentDidMount() {
    axios
      .get(`${restHostBackend}/session/token`)
      .then((response) => {
        // Save Session Token to redux store.
        this.setState({
          token: response.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async componentDidUpdate(prevProps) {
    if (
      (prevProps.data.loading && !this.props.data.loading) ||
      this.state.elementsYML !== this.props.data.entityById?.elements
    ) {
      let elementsLanguageBased = objectToArray(
          YAML.parse(this.props.data.entityById.elements)
        ),
        elementsOriginal = objectToArray(
          YAML.parse(this.props.data.entityById.elementsOriginal)
        );

      let elements = elementsOriginal.map((t1) => ({
        ...t1,
        ...elementsLanguageBased.find((t2) => t2.id === t1.id),
      }));

      elements = elements.map((item) => {
        if (item["#placeholder"] && item["#required"]) {
          item["#placeholder"] = `${item["#placeholder"]} *`;
        }

        if (item["#empty_option"] && item["#required"]) {
          item["#empty_option"] = `${item["#empty_option"]} *`;
        }

        return item;
      });

      for (let item of elements) {
        if (typeof item["#options"] === "string") {
          const id = item["#options"];
          await this.props.client
            .query({ query: WEBFORM_OPTIONS_QUERY, variables: { id: id } })
            .then((response) => {
              item["#options"] = YAML.parse(response.data?.entityById?.options);
            });
        }
      }

      this.setState({
        elements: elements,
        elementsYML: this.props.data.entityById.elements,
      });
    }
  }

  submitForm = (values, setSubmitting, resetForm) => {
    const formData = {
      webform_id: this.props.content.fieldFormRawField.first.targetId,
      ...values,
      sid: this.props.submissionId,
      langcode: this.props.reduxStore.i18n.currentLanguage,
    };

    if (
      this.props.content.fieldFormRawField.first.targetId ===
      "akkreditierung_von_pressevertret"
    ) {
      formData["denied"] = "0";
      formData["accepted"] = "0";
      formData["badge"] = "0";
    }

    this.props.client
      .mutate({
        mutation: submitMutation,
        variables: {
          data: {
            values: JSON.stringify(formData),
          },
        },
      })
      .then((response) => {
        console.log(response.data);
        setSubmitting(false);

        this.setState({
          submitResponse: response.data.submitWebform,
        });

        if (response.data.submitWebform.submission?.encodedData) {
          this.setState({
            defaultInitialValues: JSON.parse(
              response.data.submitWebform.submission?.encodedData
            ),
          });
        }

        // Success.
        if (response.data.submitWebform.submission) {
          if (!this.props.defaultInitialValues) {
            resetForm();
          }

          this.formContainer.current.scrollIntoView(true);
        }

        // Error.
        if (response.data.submitWebform.errors.length) {
          this.formContainer.current.scrollIntoView(true);
        }
      });
  };

  render() {
    if (this.props.data.loading || !this.state.elementsYML) {
      return <LoadingIndicator />;
    }

    if (this.props.data.error) {
      return <div>{this.props.data.error.message}</div>;
    }

    const { formatMessage } = this.props.intl;

    let initialValues = {},
      validationSchema = {};

    this.state.elements.forEach((item) => {
      // Do not process these element types.
      if (
        webformElementsNotProcess.includes(item["#type"]) ||
        webformElementsCustomExcluded.includes(item.id)
      ) {
        return;
      }

      // Get initial values.
      if (webFormElementsNoLevel.includes(item["#type"])) {
        // For these elements, the subelements are not nested into their
        // parents.
        item.elements.forEach((rootItem) => {
          if (webFormElementsNoLevel.includes(rootItem["#type"])) {
            initialValues = {
              ...initialValues,
              ...getInitialValue(rootItem),
            };
          } else {
            initialValues = {
              ...initialValues,
              [rootItem.id]: getInitialValue(rootItem),
            };
          }
        });
      } else {
        initialValues = {
          ...initialValues,
          [item.id]: getInitialValue(item),
        };
      }

      // Validation schema.
      if (webFormElementsNoLevel.includes(item["#type"])) {
        item.elements.forEach((rootItem) => {
          validationSchema = {
            [rootItem.id]: getValidationSchema(rootItem, this.props.intl),
            ...validationSchema,
          };
        });
      } else {
        validationSchema = {
          [item.id]: getValidationSchema(item, this.props.intl),
          ...validationSchema,
        };
      }
    });

    const formParagraphDefaultData = this.props.content.fieldFormRawField.first
      .defaultData
      ? YAML.parse(this.props.content.fieldFormRawField.first.defaultData)
      : {};

    return (
      <div className={`paragraph webform-paragraph`}>
        <Formik
          initialValues={
            this.state.defaultInitialValues
              ? {
                  ...initialValues,
                  ...formParagraphDefaultData,
                  ...this.state.defaultInitialValues,
                }
              : { ...initialValues, ...formParagraphDefaultData }
          }
          validationSchema={yup.lazy((values) => {
            this.state.elements.forEach((item) => {
              // Do not process these element types.
              if (
                webformElementsNotProcess.includes(item["#type"]) ||
                webformElementsCustomExcluded.includes(item.id)
              ) {
                return;
              }

              // Validation schema.
              if (webFormElementsNoLevel.includes(item["#type"])) {
                item.elements.forEach((rootItem) => {
                  validationSchema = {
                    [rootItem.id]: getValidationSchema(
                      rootItem,
                      this.props.intl,
                      values
                    ),
                    ...validationSchema,
                  };
                });
              } else {
                validationSchema = {
                  [item.id]: getValidationSchema(item, this.props.intl, values),
                  ...validationSchema,
                };
              }
            });

            return yup.object().shape(validationSchema);
          })}
          onSubmit={(values, { setSubmitting, resetForm }) =>
            this.submitForm(values, setSubmitting, resetForm)
          }
        >
          {(formik) => (
            <div
              className={`container webform-container`}
              ref={this.formContainer}
            >
              <div className="row">
                <div className={`col-16 offset-lg-2 col-lg-12`}>
                  {this.state.submitResponse &&
                    !this.state.submitResponse.submission &&
                    this.state.submitResponse.errors.length && (
                      <div
                        className={`${this.props.content.fieldFormRawField.first.targetId} form-messages`}
                      >
                        <div className="alert alert-danger" role="alert">
                          {this.state.submitResponse.errors.map(
                            (item, index) => (
                              <React.Fragment key={index}>
                                <span>{item.message}</span>
                                <br />
                              </React.Fragment>
                            )
                          )}
                        </div>
                      </div>
                    )}

                  {this.state.submitResponse &&
                    this.state.submitResponse.submission &&
                    !this.state.submitResponse.errors.length && (
                      <div
                        className={`${this.props.content.fieldFormRawField.first.targetId} form-messages`}
                      >
                        <div className="alert alert-success" role="alert">
                          {formatMessage({ id: "webforms.success" })}
                        </div>
                      </div>
                    )}

                  <Form
                    noValidate
                    className={
                      this.props.content.fieldFormRawField.first.targetId
                    }
                  >
                    <WebformElements
                      formik={formik}
                      items={this.state.elements}
                      token={this.state.token}
                      generatedInitialValues={initialValues}
                    />

                    <ErrorFocus
                      isSubmitting={formik.isSubmitting}
                      errors={formik.errors}
                      isValidating={formik.isValidating}
                    />

                    {formik.isSubmitting && <LoadingIndicator />}
                  </Form>
                </div>
              </div>
            </div>
          )}
        </Formik>
      </div>
    );
  }
}

ParagraphForm.propTypes = {
  client: PropTypes.object.isRequired,
  content: PropTypes.shape({
    fieldFormRawField: PropTypes.shape({
      first: PropTypes.shape({
        targetId: PropTypes.string,
        defaultData: PropTypes.string,
      }),
    }),
  }),
  data: PropTypes.shape({
    loading: PropTypes.bool,
    error: PropTypes.shape({
      message: PropTypes.string,
    }),
    entityById: PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      elements: PropTypes.string,
      elementsOriginal: PropTypes.string,
    }),
  }),
  defaultInitialValues: PropTypes.object,
  submissionId: PropTypes.string,
  reduxStore: PropTypes.object,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(
  connect(mapStateToProps)(
    graphql(webformQuery, {
      options: (props) => ({
        variables: {
          id: props.content.fieldFormRawField.first.targetId,
        },
      }),
    })(withApollo(ParagraphForm))
  )
);
